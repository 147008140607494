import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import Carousel from '../components/Carousel'
import Cover from '../components/Cover'
import Cta from '../components/Cta'
import Text from '../components/Text'
import CardList from '../components/CardList'
import TextList from '../components/TextList'

// Content
import { aboutUsContent } from '../content/aboutUs'

const AboutUsPage = () => {
  const { t } = useTranslation()
  return (
    <Layout headerWhite={true}>
      <Cover content={aboutUsContent(t).cover} />
      <Carousel content={aboutUsContent(t).journey.carousel} />
      <CardList content={aboutUsContent(t).cardlist} />
      <TextList content={aboutUsContent(t).textlist} />
      <Text content={aboutUsContent(t).text} />
      <Cta content={aboutUsContent(t).cta} />
    </Layout>
  )
}

export const Head = ({ location }) => (
  <Seo
    title='About Us'
    description='We connect all players in the textile value chain. We are a global network that brings people, organizations, and innovative ideas together.'
    pathname={location.pathname}
  />
)

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default AboutUsPage
