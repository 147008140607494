import * as React from 'react'

// Components
import LeaderCard from '../components/LeaderCard'

// Images
import background from '../assets/images/covers/about-us-background.svg'
import background1 from '../assets/images/cta/background1.jpg'
import employees from '../assets/images/journey/fs-employees-graphic.svg'
import locations from '../assets/images/journey/fs-global-locations-graphic.svg'
import launched from '../assets/images/journey/fs-launched-graphic.svg'
import jonasWand from '../assets/images/leaders/jonas-wand.jpg'
import yaraBanyAtta from '../assets/images/leaders/yara-bany-atta.jpg'
import chingFenLai from '../assets/images/leaders/ching-fen-lai.jpg'
import kumanSuerAri from '../assets/images/leaders/kuman-suer-ari.jpg'
import inesMilhazes from '../assets/images/leaders/ines-milhazes.jpg'
import michaelMaier from '../assets/images/staff/michael-maier.jpg'
import alexandreViana from '../assets/images/staff/alexandre-viana.jpg'
import doneDogrusoz from '../assets/images/staff/done-gul.jpg'
import sabihaDavid from '../assets/images/staff/sabiha-gamze-david.jpg'
import joaoMoreira from '../assets/images/staff/joao-moreira.jpg'
import anaCorreia from '../assets/images/staff/ana-correia.jpg'
import reginaVeiga from '../assets/images/staff/regina-veiga.jpg'
import sheilaLu from '../assets/images/staff/sheila-lu.jpg'

export const aboutUsContent = (t) => {
  return {
    cover: {
      background: background,
      title: t('About Us'),
      text: t(
        'We connect all players in the textile value chain. But our purpose goes further than that. We are a global network that brings people, organizations, and innovative ideas together. We make textile sourcing smarter, transparent, and more sustainable.'
      ),
      cta: {
        text: t('Join now'),
        url: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
      },
    },
    journey: {
      carousel: {
        type: 'smallCard',
        extraClassCarousel: 'journeyCarousel',
        extraClassSlide: 'journeyCarouselSlide',
        slidesConfig: {
          transitionDuration: 400,
          autoplay: false,
          pauseOnHover: false,
          infinite: true,
          slidesToScroll: 1,
          slidesToScrollDesktop: 1,
          canSwipe: 'tablet',
          indicators: 'tablet',
          slidesMobile: 1,
          slidesTablet: 2,
          slidesDesktop: 3,
          slidesBigDesktop: 3,
        },
        cards: [
          {
            mode: 'light',
            arrow: false,
            text: t('2017'),
            linkText: t('Launched'),
            img: launched,
          },
          {
            mode: 'light',
            arrow: false,
            text: t('6'),
            linkText: t('Global locations'),
            img: locations,
          },
          {
            mode: 'light',
            arrow: false,
            text: t('> 80'),
            linkText: t('Employees'),
            img: employees,
          },
        ],
      },
    },
    cardlist: {
      title: 'Meet The Team',
      component: <LeaderCard />,
      items: [
        {
          photo: jonasWand,
          name: 'Jonas Wand',
          role: 'Co-Founder & Chief Executive Officer (CEO)',
        },
        {
          photo: yaraBanyAtta,
          name: 'Yara Bany Atta',
          role: 'Head of Operations',
        },
        {
          photo: chingFenLai,
          name: 'Ching Fen Lai',
          role: 'Global Sales Operations Manager',
        },
        {
          photo: kumanSuerAri,
          name: 'Kuman Süer Ari',
          role: 'Head of Sales Turkey',
        },
        {
          photo: inesMilhazes,
          name: 'Inês Milhazes',
          role: 'Marketing Team Lead',
        },
        {
          photo: michaelMaier,
          name: 'Michael Maier',
          role: 'Finance Manager',
        },
        {
          photo: alexandreViana,
          name: 'Alexandre Viana',
          role: 'Senior Graphic Designer',
        },
        {
          photo: doneDogrusoz,
          name: 'Döne Gül Doğrusöz',
          role: 'Sales Representative Turkey',
        },
        {
          photo: sabihaDavid,
          name: 'Sabiha Gamze David',
          role: 'Customer Success Turkey',
        },
        {
          photo: joaoMoreira,
          name: 'João Moreira',
          role: 'Account Executive Portugal',
        },
        {
          photo: anaCorreia,
          name: 'Ana Correia',
          role: 'Sales Representative Portugal',
        },
        {
          photo: reginaVeiga,
          name: 'Regina Veiga',
          role: 'Customer Success Portugal',
        },
        {
          photo: sheilaLu,
          name: 'Sheila Lu',
          role: 'Customer Success Manager',
        },

      ],
    },
    textlist: {
      title: "Foursource's Core Values Are Reflected In Everything We Do",
      items: [
        {
          title: 'Focus On Customers',
          description:
            'We are achievement driven. We measure our achievements on happy customers, meeting their needs and providing the best user experience.',
        },
        {
          title: 'Empower those around us',
          description:
            'We give responsibility, show respect and gratitude to every team member to encourage them to grow by using their strengths and talents.',
        },
        {
          title: 'Ask for help',
          description: 'We see showing vulnerability as a sign of strength and a key stepping stone to success.',
        },
        {
          title: 'Dare to Make mistakes',
          description:
            'Innovation comes with failure. We celebrate when we make mistakes because we know we’re one step closer to getting it right.',
        },
        {
          title: 'Collaborate With Transparency',
          description:
            'We opt for honesty, openness, and clarity in communication to build trust, better working relationships and faster solutions.',
        },
        {
          title: 'Have Fun',
          description:
            'We focus on the positives. We want to build a passionate team that creates fun and happiness at work.',
        },
      ],
    },
    text: {
      backgroundColor: 'bluishGrey.one',
      title: 'Our Global Approach',
      description:
        'We follow a global approach with subsidiaries and partner offices in a growing number of countries. FOURSOURCE is headquartered in Berlin (Germany) and our technology and development center is located in Porto (Portugal). We currently operate Sales & Customer Success entities in Izmir (Turkey), Shanghai (China), Mumbai (India), and Karachi (Pakistan). To get in touch, please send an email to <a href="mailto:hello@foursource.com"><u>hello [@] foursource.com</u></a> .',
    },
    cta: {
      title: 'Interested to Join The Team?',
      subhead: 'Contact Us',
      bg: {
        image: background1,
      },
      buttons: [
        {
          text: 'jobs [@] foursource.com',
          href: 'mailto:jobs@foursource.com',
          component: 'Link',
          variant: 'button',
          color: 'white',
          target: '_blank',
          rel: 'noreferrer',
        },
      ],
    },
  }
}
