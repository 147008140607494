// Base
import * as React from 'react'
import Parser from 'html-react-parser'

// Components
import { Box, Container, Typography } from '@mui/material'
import CtaButton from '../CtaButton'

// Assets
import SouthRoundedIcon from '@mui/icons-material/SouthRounded'

const Text = ({ content }) => {
  return (
    <Box sx={{ my: { xs: 6, md: 8 }, mx: 2 }}>
      <Container
        disableGutters
        maxWidth='xl'
        sx={{
          textAlign: 'center',
          py: { xs: 6, md: 8 },
          borderRadius: 3,
          backgroundColor: content?.backgroundColor,
          color: content?.textColor,
        }}
      >
        <Container maxWidth='md' sx={{ px: 0 }}>
          {content?.title && (
            <Typography variant='h2' sx={{ color: content?.textColor || 'bluishGrey.ten', mb: 3 }}>
              {Parser(content.title)}
            </Typography>
          )}
          {content?.description && (
            <Typography variant='body1' sx={{ mb: content?.skipToResults && 6 }}>
              {Parser(content.description)}
            </Typography>
          )}
          {content?.skipToResults && (
            <CtaButton
              content={{
                text: 'Skip to the results',
                href: '#results',
                color: 'primary',
                variant: 'body2Bold',
                component: 'Link',
              }}
            >
              <SouthRoundedIcon sx={{ ml: 1, mt: '-1px' }} />
            </CtaButton>
          )}
        </Container>
      </Container>
    </Box>
  )
}
export default Text
