import styled from 'styled-components'

export const Image = styled.img`
  width: 100%;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 16px;
  &:hover {
    opacity: 1;
  }
`
export const GridItem = styled.div.attrs({
  className: 'col-sm-6 col-md-4 col-xl-3',
})`
  padding: 12px !important;
  text-align: center;
`
