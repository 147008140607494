// Base
import * as React from 'react'

// Components
import { Paper, Typography } from '@mui/material'

// Styles
import * as S from './LeaderCard.styles'

const LeaderCard = ({ content }) => {
  return (
    <S.GridItem>
      <Paper
        sx={{
          backgroundColor: 'background.default',
          boxShadow: '0px 8px 16px rgba(171, 190, 209, 0.4)',
          height: '100%',
          textAlign: 'center',
          borderRadius: 1.5,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <S.Image src={typeof content.photo === 'object' ? content.photo.publicURL : content.photo} alt={content.name} />
        <S.Overlay>
          <Typography
            variant='h3'
            sx={{
              mb: 2,
              height: { sm: '70px' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {content.name}
          </Typography>
          <Typography variant='lead'>{content.role}</Typography>
        </S.Overlay>
      </Paper>
    </S.GridItem>
  )
}
export default LeaderCard
